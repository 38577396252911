import React, { useEffect, useContext, useState, useRef } from "react";
import styled from "styled-components";
import ActionsContext from "common/ActionsContext";
import SubscriberDetailHelpModal from "./Help";
import { ValuesTable } from "./ValuesTable";
import { ActiveIpAddressesTable } from "./ActiveIpAddressesTable";
import Modal from "common/Modal.jsx";
import { isValidIPValue, isIPv4IPv6Range } from "common/api";
import TextInput from "common/TextInput";
import RatePolicy from "./RatePolicy";
import Shaping from "./Shaping";
import { safeStr } from "common/api";
import SelectInput from "common/SelectInput";
import { createPortal } from 'react-dom';
import { TitleWithNav } from "common/TitleWithNav";

async function addIpAddressToSubscriberGroup(ipAddress, subscriberGroup) {
  if(isIPv4IPv6Range(ipAddress)){
    return ifCl.run(
      `api subscriber-group ${safeStr(subscriberGroup)} subscriber-range ${ipAddress}`
    ); 
  }else{
    return ifCl.run(
      `api subscriber ${ipAddress} subscriber-group ${safeStr(subscriberGroup)}`
    );
  }
}

async function addSubscriberIdToSubscriberGroup(subscriberId, subscriberGroup) {
  return ifCl.run(
    `api subscriber-group ${safeStr(subscriberGroup)} subscriber-id ${safeStr(subscriberId)}`
  );
}

async function addVlanIdToSubscriberGroup(vlanId, subscriberGroup, vlanOption) {
  return ifCl.run(
    `api subscriber-group ${safeStr(subscriberGroup)} vlan ${vlanOption==='vlanId'?vlanId:'notag'}`
  );
}

async function clearSubscriberGroup(subscriberGroup) {
}

const ContextMenu = ({ onAddValue, onMergeReplace }) => {
  const actions = useContext(ActionsContext);

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
      </li>
      <li>
        <a
          onClick={() => actions.send("do-load")}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
      <li className="dropdown hidden-to-operators">
        <a
          className="dropdown-toggle"
          data-toggle="dropdown"
          role="button"
          aria-haspopup="true"
          aria-expanded="false"
          title="Options"
        >
          <i className="material-icons">more_vert</i>
        </a>
        <ul className="dropdown-menu pull-right">
          <li>
            <a onClick={() => onAddValue("ip")}>
              Add IP Address/Range...
            </a>
          </li>
          <li>
            <a onClick={() => onMergeReplace("merge", "ip")}>
              Merge IP Adresses from File...
            </a>
          </li>
          <li>
            <a onClick={() => onMergeReplace("replace", "ip")}>
              Replace IP Adresses with File...
            </a>
          </li>
          <li>
            <a onClick={() => onAddValue("id")}>
              Add Subscriber ID...
            </a>
          </li>
          <li>
            <a onClick={() => onMergeReplace("merge", "id")}>
              Merge Subscriber IDs from File...
            </a>
          </li>
          <li>
            <a onClick={() => onMergeReplace("replace", "id")}>
              Replace Subscriber IDs with File...
            </a>
          </li>
          <li>
            <a onClick={() => onAddValue("vlan")}>
              Add VLAN ID...
            </a>
          </li>
        </ul>
      </li>
    </ul>
  );
};

const AllSubscribersContextMenu = () => {
  const actions = useContext(ActionsContext);

  return (
    <ul className="context-menu header-dropdown m-r--5">
      <li>
        <a
          data-loading-effect="pulse"
          data-toggle="cardloading"
          title="Help"
          onClick={() => actions.send("open-help")}
        >
          <i className="material-icons">help_outline</i>
        </a>
      </li>
      <li>
        <a
          onClick={() => actions.send("do-load")}
          data-toggle="cardloading"
          data-loading-effect="pulse"
          title="Refresh"
        >
          <i className="material-icons">refresh</i>
        </a>
      </li>
    </ul>
  );
};
const HeaderDiv = styled.div`
  display: flex;
  flex-direction: column;
  & .context-menu {
    display: flex;
    flex-direction: row;
  }
`;

const ModalDiv = styled.div`
  display: flex;
  align-items: center;
`;

const CurrentIpAddressesH5 = styled.h5`
  margin-bottom: 63px;
  @media screen and (max-width: 1200px) {
    margin-bottom: 30px;
  }
`;

export const applyParams = (child, params = {}) =>
  React.isValidElement(child) ? React.cloneElement(child, params) : child;

const CreateAddIpAddressModal = ({
  onClose = doesNothing,
  subscriberGroup,
}) => {
  const [ipAddress, setIpAddress] = useState("");
  const [error, setError] = useState(null);

  const actions = useContext(ActionsContext);
  const reloadWhenDone = () => actions.send("do-load");

  const updateIpAddress = (value) => {
    setIpAddress(value);

    if (error) {
      setError(null);
    }
  };

  async function onSaveIpAddress() {
    const ipAddressTrimmed = ipAddress.trim();
    if (ipAddressTrimmed.length === 0 || ipAddressTrimmed.includes(" ")) {
      return setError("Invalid IP address");
    }

    try {
      const response = await addIpAddressToSubscriberGroup(
        ipAddressTrimmed,
        subscriberGroup
      );
      if (response.startsWith("%WARN")) {
        return setError(response);
      }

      reloadWhenDone();
      onClose();
    } catch (e) {
      return setError(e.message ?? e);
    }
  }

  return (
    <Modal
      title="Add IP Address/Range"
      superIcon="add_circle"
      content={() => (
        <IpAddressInput name={ipAddress} onChange={updateIpAddress} />
      )}
      applyLabel="OK"
      onApply={onSaveIpAddress}
      applyDisabled={ipAddress.length === 0 || error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <div className="row">
            <span className="modal-err-msg color-red align-left">{error}</span>
          </div>
        )) ||
        null
      }
    />
  );
};

const AddSubscriberIdModal = ({ onClose = doesNothing, subscriberGroup }) => {
  const [subscriberId, setSubscriberId] = useState("");
  const [error, setError] = useState(null);

  const actions = useContext(ActionsContext);
  const reloadWhenDone = () => actions.send("do-load");

  const updateSubscriberId = (event) => {
    setSubscriberId(event.target.value);

    if (error) {
      setError(null);
    }
  };

  async function onSaveSubscriberId() {
    const subscriberIdTrimmed = subscriberId.trim();

    if (subscriberIdTrimmed.length === 0 || subscriberIdTrimmed.includes(" ")) {
      return setError("Invalid Subscriber ID");
    }

    try {
      const response = await addSubscriberIdToSubscriberGroup(
        subscriberIdTrimmed,
        subscriberGroup
      );
      if (response.startsWith("%WARN")) {
        return setError(response);
      }

      reloadWhenDone();
      onClose();
    } catch (e) {
      return setError(e.message ?? e);
    }
  }

  return (
    <Modal
      title="Add Subscriber ID"
      superIcon="add_circle"
      content={() => (
        <div className="form-group margin-b-0">
          <label>Subscriber ID:</label>
          <TextInput
            name={subscriberId}
            onChange={updateSubscriberId}
            iconOption={true}
            icon="cloud_queue"
            label=""
          />
        </div>
      )}
      applyLabel="OK"
      onApply={onSaveSubscriberId}
      applyDisabled={subscriberId.length === 0 || error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <div className="row">
            <span className="modal-err-msg color-red align-left">{error}</span>
          </div>
        )) ||
        null
      }
    />
  );
};

const AddVlanIdModal = ({ onClose = doesNothing, subscriberGroup }) => {
  const [vlanId, setVlanId] = useState("");
  const [vlanOption, setVlanOption] = useState("vlanId");
  const [error, setError] = useState(null);

  const actions = useContext(ActionsContext);
  const reloadWhenDone = () => actions.send("do-load");

  const updateVlanId = (event) => {
    setVlanId(event.target.value);

    if (error) {
      setError(null);
    }
  };

  async function onSaveVlanId() {
    const vlanIdTrimmed = vlanId.trim();

    if (
      vlanOption !== 'notag' &&
      (vlanIdTrimmed.length === 0 || 
      vlanIdTrimmed.includes(" ") || 
      isNaN(vlanIdTrimmed) || 
      Number(vlanIdTrimmed<0 || 
      Number(vlanIdTrimmed>4095)))
    ) {
      return setError("Invalid VLAN value");
    }

    try {
      const response = await addVlanIdToSubscriberGroup(
        vlanIdTrimmed,
        subscriberGroup,
        vlanOption
      );
      if (response.startsWith("%WARN")) {
        return setError(response);
      }

      reloadWhenDone();
      onClose();
    } catch (e) {
      return setError(e.message ?? e);
    }
  }

  return (
    <Modal
      title="Add VLAN ID"
      superIcon="add_circle"
      content={() => (
        <ModalDiv className="margin-b-0 row">
          <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-8">
              <SelectInput 
                label="VLAN-ID"
                value={vlanOption} 
                options={[
                  {label: 'VLAN Tag (0-4095):', value:'vlanId'}, 
                  {label: 'No-VLAN-Tag', value: 'notag'}
                ]} 
                onChange={(event) => {
                  if (error) {
                    setError(null);
                  }

                  setVlanOption(event.target.value)
                }}
                defaultValue={vlanOption}
                className="margin-t-10"
              />
            </div>
            {vlanOption === 'vlanId' && 
            <div className="col-lg-6 col-md-6 col-sm-6 col-xs-4">
              <TextInput
                name={vlanId}
                onChange={updateVlanId}
                iconOption={true}
                icon="cloud_queue"
                label=""
                className="margin-0"
              />
            </div>
          }
          </div>
        </ModalDiv>
      )}
      applyLabel="OK"
      onApply={onSaveVlanId}
      applyDisabled={(vlanOption !== 'notag' && vlanId.length === 0) || error !== null}
      closeLabel="CANCEL"
      onClose={onClose}
      footerMessage={
        (error !== null && (
          <div className="row">
            <span className="modal-err-msg color-red align-left">{error}</span>
          </div>
        )) ||
        null
      }
    />
  );
};

const IpAddressInput = ({ ipAddress, onChange }) => (
  <div className="form-group margin-b-0">
    <label>IP Address Range:</label>
    <div className="input-group margin-b-0">
      <span className="input-group-addon">
        <i className="material-icons">cloud_queue</i>
      </span>
      <div className="form-line">
        <input
          type="text"
          className="form-control align-left"
          required
          autofocus
          onKeyUp={(event) => onChange(event.target.value)}
          defaultValue={ipAddress}
        />
      </div>
    </div>
  </div>
);

let isCancelled = false;

const LoadFromFileModal = ({
  onClose = doesNothing,
  subscriberGroup,
  action,
  entity,
}) => {
  const [errors, setErrors] = useState([]);
  const [info, setInfo] = useState(null);
  const [isSaving, setIsSaving] = useState(null);
  const actions = useContext(ActionsContext);
  const inputFileRef = useRef();

  const reloadWhenDone = () => actions.send("do-load");

  const batchSize = 30;

  function onFileLoaded(e, entity) {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onload = function (ev) {
      if (ev.target.error) {
        setErrors(["FileInput.error: " + evt.target.error]);
      } else {
        const items = parseFile(ev.target.result);
        saveItems(items, subscriberGroup);
      }
    };

    reader.readAsText(file);
  }

  function parseFile(content) {
    const entries = [];

    const trimmedContent = content.trim();

    if (!trimmedContent) {
      setErrors(["Empty file."]);
      return [];
    }

    try {
      const lines = trimmedContent.replaceAll('\r', '').split("\n");

      if (lines.length === 0) {
        return [];
      }

      for (const line of lines) {
        if (!line) {
          continue;
        }

        if (entity === "ip") {
          if (isValidIPValue(line)) {
            entries.push(line);
          } else {
            setErrors([`Invalid IP address: ${line}`]);
            return [];
          }
        } else {
          entries.push(line);
        }
      }
    } catch (e) {
      setErrors([e.message]);
      return [];
    }

    return entries;
  }

  async function saveItems(items, subscriberGroup) {
    const errorsUpdate = [];
    isCancelled = false;
    setIsSaving(true);

    if (items.length === 0) {
      return;
    }

    const entityName = entity === "ip" ? "IP Adresses" : "Subscriber IDs";

    setInfo(`Loading ${entityName}...`);

    if (action === "replace") {
      try {
        await clearSubscriberGroup(subscriberGroup);
      } catch (e) {}
    }

    for (let i = 0; i < items.length && !isCancelled; i += batchSize) {
      setInfo(`Loading ${entityName}: ${i} of ${items.length} loaded.`);
      try {
        const response = await addItemsToSubscriberGroup(
          items.slice(i, i + batchSize),
          subscriberGroup
        );
        if (response.startsWith("%WARN")) {
          errorsUpdate.push(response);
        }
      } catch (e) {
        errorsUpdate.push(e);
      }
    }

    if (!isCancelled) {
      if (errorsUpdate.length === 0) {
        setInfo(
          `${entity === "ip" ? "Addresses" : "Subscriber IDs"} in file ${
            inputFileRef.current.files[0].name
          } loaded.`,
          null
        );
      }

      setErrors(errorsUpdate);
    }

    setIsSaving(false);

    reloadWhenDone();
  }

  async function addItemsToSubscriberGroup(items, subscriberGroup) {
    let commands = [];

    if (entity === "ip") {
      commands = items.map(
        (ipAddress) =>
          `api subscriber ${ipAddress} subscriber-group ${safeStr(subscriberGroup)}`
      );
    } else {
      commands = items.map(
        (subscriberId) =>
          `api subscriber-group ${safeStr(subscriberGroup)} subscriber-id ${safeStr(subscriberId)}`
      );
    }

    return new Promise(function (resolve, reject) {
      ifCl.executeBatch(commands.join("\n ") + "\n", function (res) {
        if (res.CmdResponse.response.indexOf("%ERR") > -1) {
          reject(res.CmdResponse.response);
        } else {
          resolve(res.CmdResponse.response);
        }
      });
    });
  }

  const entityName = entity === "ip" ? "IP Adresses" : "Subscriber IDs";

  return (
    <Modal
      title={
        action === "replace"
          ? `Replace ${entityName} with File`
          : `Merge ${entityName} from File`
      }
      superIcon="add_circle"
      content={() => (
        <div>
          <input
            id="idAddressFileInput"
            type="file"
            onChange={onFileLoaded}
            accept=".txt"
            ref={inputFileRef}
            onClick={() => {
              inputFileRef.current.value = null;
            }}
          />
        </div>
      )}
      applyLabel="OK"
      onApply={onClose}
      applyDisabled={isSaving}
      closeLabel="CANCEL"
      onClose={() => {
        isCancelled = true;
        onClose();
      }}
      footerMessage={
        (errors.length > 0 && (
          <div>
            <div className="row">
              <span className="modal-err-msg color-red align-left">
                There were some errors uploading the file:
              </span>
            </div>
            {errors.map((error) => (
              <div className="row">
                <span className="modal-err-msg color-red align-left">
                  {error}
                </span>
              </div>
            ))}
          </div>
        )) ||
        (info !== null && (
          <div className="row">
            <span className="modal-err-msg align-left">{info}</span>
          </div>
        )) ||
        null
      }
    />
  );
};

const SubscriberGroupEdit = ({ subscriberGroup, returnView, direction, timePeriod, metric, ipAddress }) => {
  const [showAddIpAddressModal, setShowAddIpAddressModal] = useState(false);
  const [showAddSubscriberIdModal, setShowAddSubscriberIdModal] = useState(false);
  const [showAddVlanIdModal, setShowAddVlanIdModal] = useState(false);
  const [showLoadFromFileModal, setShowLoadFromFileModal] = useState({
    show: false,
    action: null,
    entity: null,
  });
  const actions = useContext(ActionsContext);

  useEffect(() => () => actions.clear(), []);

  function onMergeReplace(action, entity) {
    setShowLoadFromFileModal({ show: true, action, entity });
  }

  return (
    <div className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header">
          <TitleWithNav returnView={returnView}>
            <h2 className="title">
              Edit Subscriber Group
              <span className="case-sensitive"> {subscriberGroup}</span>
            </h2>
          </TitleWithNav>
          {subscriberGroup === 'all-subscribers'
            ? <AllSubscribersContextMenu />
            : <ContextMenu
                onAddValue={(entity) => {
                if (entity === "ip") {
                  setShowAddIpAddressModal(true);
                } else if(entity === 'id'){
                  setShowAddSubscriberIdModal(true);
                } else if(entity === 'vlan'){
                  setShowAddVlanIdModal(true);
                }
              }}
              onMergeReplace={onMergeReplace}
            />
          }
        </HeaderDiv>
        <div className="body col-lg-12 col-md-12 col-sm-12 col-xs-12 padding-t-10">
          <RatePolicy nameGroup={subscriberGroup} />    
          {subscriberGroup === 'all-subscribers' ? null : (
          <div className="body flex-panel-column col-lg-6 padding-l-0 padding-r-0 padding-t-20">
            <h5>Provisioned IP Addresses/Ranges, Subscriber IDs and VLAN IDs </h5>
            <button
              type="button"
              className="btn btn-default waves-effect shadow-none margin-b-15 hidden-to-operators"
              onClick={() => setShowAddIpAddressModal(true)}
            >
              <i className="material-icons">add_circle</i>
              <span>Add IP Address/Range...</span>
            </button>
            <button
              type="button"
              className="btn btn-default waves-effect shadow-none margin-b-15 hidden-to-operators"
              onClick={() => setShowAddSubscriberIdModal(true)}
            >
              <i className="material-icons">add_circle</i>
              <span>Add Subscriber ID...</span>
            </button>
            <button
              type="button"
              className="btn btn-default waves-effect shadow-none margin-b-15 hidden-to-operators"
              onClick={() => setShowAddVlanIdModal(true)}
            >
              <i className="material-icons">add_circle</i>
              <span>Add VLAN ID...</span>
            </button>
            <ValuesTable subscriberGroup={subscriberGroup} />
          </div>
          )}
          <div className="body flex-panel-column col-lg-6 padding-t-20">
            <CurrentIpAddressesH5>Current IP Addresses </CurrentIpAddressesH5>
            <ActiveIpAddressesTable subscriberGroup={subscriberGroup} />
          </div>
        </div>
        <SubscriberDetailHelpModal />
        {showAddIpAddressModal &&
          createPortal(
            <CreateAddIpAddressModal
              onClose={() => setShowAddIpAddressModal(false)}
              subscriberGroup={subscriberGroup}
            />,
            document.body
          )}
        {showAddSubscriberIdModal &&
          createPortal(
            <AddSubscriberIdModal
              onClose={() => setShowAddSubscriberIdModal(false)}
              subscriberGroup={subscriberGroup}
            />,
            document.body
          )}
        {showAddVlanIdModal &&
          createPortal(
            <AddVlanIdModal
              onClose={() => setShowAddVlanIdModal(false)}
              subscriberGroup={subscriberGroup}
            />,
            document.body
          )}
        {showLoadFromFileModal.show &&
          createPortal(
            <LoadFromFileModal
              onClose={() => setShowLoadFromFileModal({ show: false })}
              subscriberGroup={subscriberGroup}
              action={showLoadFromFileModal.action}
              entity={showLoadFromFileModal.entity}
            />,
            document.body
          )}
        <div className="control modal-footer p-b-30">
        </div>
      </div>
    </div>
  );
};

export default SubscriberGroupEdit;
