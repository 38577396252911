import React, { useState, useEffect } from "react";
import * as d3 from "d3";
import GroupBarChart from "common/graphs/GroupBarChart";
import Canvas from "common/graphs/Canvas";
import Axis from "common/graphs/Axis";
import LabelAxis from "common/graphs/LabelAxis";
import styled from "styled-components";

const Title = styled.span`
  font-size: 18px;
  margin: 0.1em 0.25em;
`;

const TCPOContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;

  span.opt {
    color: var(--text-primary);
  }
  
  & span.opt,
  & .group-label {
    span.opt {
      color: var(--text-primary);
    }
  }
`;

const CenteredMessage = styled.div`
  display: flex;
  flex: 1 1 100%;
  align-items: center;
  justify-content: center;
`;

const margin = { top: 30, right: 2, bottom: 2, left: 2 };

const withKey = (field) => (item) => ({ ...item, key: item[field] });

const _defaultValueFormat = d3.format(",.1f");
const _defaultGroupLabel = (d) => (
  <>
    {d.label === false ? "" : `${d.label || d.name || ""}:`}
    <span className="opt">
      {" "}
      {d.accel === null
        ? "n/s"
        : (d.accel <= 0 ? "" : "+") + _defaultValueFormat(d.accel * 100) + "%"}
    </span>
  </>
);

const _defaultFieldLabel = (d) => {
  return d.value === null
    ? "n/a"
    : d.value !== undefined && _defaultValueFormat(d.value);
};

const TCPOFact = ({
  title,
  items = [],
  groupLabel = _defaultGroupLabel,
  fieldLabel = _defaultFieldLabel,
  columns = [],
  missingText = "Not enough samples",
  independentScales = false,
}) => {
  if (items.length === 0) {
    return (
      <TCPOContainer>
        <Title>{title}</Title>
        <CenteredMessage>{missingText}</CenteredMessage>
      </TCPOContainer>
    );
  }
  const grouped = items.map(withKey("name"));
  return (
    <TCPOContainer>
      <Title>{title}</Title>
      <GroupBarChart
        key={"name"}
        data={grouped}
        columns={columns}
        xFrom="x"
        yFrom="y"
        margin={margin}
        groupLabel={groupLabel}
        fieldLabel={fieldLabel}
        independentScales={independentScales}
      />
    </TCPOContainer>
  );
};
export default TCPOFact;
