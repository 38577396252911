import React, { useState, useEffect, useRef, useContext } from "react";
import Tabs, { Tab } from "common/layouts/Tabs";
import TCPOptimizationSpeedHelpModal from "./Help";
import {
  HeaderDiv,
  ViewContainer,
  ContextMenu,
  LabelRetrieval,
  FilteringSettings,
  DaysContext,
} from "../common";
import ActionsContext from "common/ActionsContext";

const speedFacets = ({
  RttiLo = "0-10ms",
  RttiMd = "10-60ms",
  RttiHi = ">60ms",
}) => [
  {
    label: "All Internet Latencies",
    units: "%",
    fields: [
      {
        name: "global",
        label: "Acceleration",
        color: "var(--bta-color)",
        area: true,
      },
    ],
    noDataMessage: "Not enough samples or no significant acceleration",
  },
  {
    label: `${RttiLo} Internet Latency`,
    units: "%",
    fields: [
      {
        name: "small",
        label: "Acceleration",
        color: "var(--bta-color)",
        area: true,
      },
    ],
    noDataMessage: "Not enough samples or no significant acceleration",
  },
  {
    label: `${RttiMd} Internet Latency`,
    units: "%",
    fields: [
      {
        name: "medium",
        label: "Acceleration",
        color: "var(--bta-color)",
        area: true,
      },
    ],
    noDataMessage: "Not enough samples or no significant acceleration",
  },
  {
    label: `${RttiHi} Internet Latency`,
    units: "%",
    fields: [
      {
        name: "large",
        label: "Acceleration",
        color: "var(--bta-color)",
        area: true,
      },
    ],
    noDataMessage: "Not enough samples or no significant acceleration",
  },
];

export const TCPOComparison = ({ hours = 24 }) => {
  const [params, setParams] = useState({ hours });
  const onHoursChange = (hours) => setParams({ hours });
  return (
    <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">TCP connections acceleration over time</h2>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column">
          <LabelRetrieval>
            {(labels) => (
              <Tabs>
                <Tab label="DOWNLOAD" id="down" active={true}>
                  <FilteringSettings
                    direction="down"
                    type="acceleration"
                    {...params}
                    onHoursChange={onHoursChange}
                  >
                    {(result) => (
                      <DaysContext facets={speedFacets(labels)} {...result} />
                    )}
                  </FilteringSettings>
                </Tab>
                <Tab label="UPLOAD" id="up">
                  <FilteringSettings
                    direction="up"
                    type="acceleration"
                    {...params}
                    onHoursChange={onHoursChange}
                  >
                    {(result) => (
                      <DaysContext facets={speedFacets(labels)} {...result} />
                    )}
                  </FilteringSettings>
                </Tab>
              </Tabs>
            )}
          </LabelRetrieval>
        </div>
      </div>
      <TCPOptimizationSpeedHelpModal />
    </ViewContainer>
  );
};