
// TEst
import { useEffect, useState } from "react";
import {
  createBrowserRouter,
  RouterProvider,
  useParams,
  useNavigate,
  createSearchParams,
  useSearchParams,
} from "react-router-dom";
import ActionableView from "./common/ActionableView";
import VerifiedSubcriberDetail from "views/Statistics/SubscriberDetail/component";
import {SubscriberGroupsAndDashboard} from "views/Status/SubscriberGroups/SubscriberGroupsAndDashboard";
import {SubscribersAndDetails} from "views/Status/Subscribers/SubscribersAndDetails";
import SubscriberGroupDashboard from "views/Statistics/SubscriberGroupDashboard/component";
import BtaTraffic from "views/Statistics/Throughput/BtaTraffic/component";
import Interfaces from "views/Statistics/Throughput/Interfaces/component";
import SubscriberGroupEdit from "views/Status/SubscriberGroupsEdit/component";
import TCPOComparison from "views/Status/TCPOComparison/component";
import FlowsPerLPolicyHourly from "views/Statistics/Flows/PerPolicy/component";
import RatePolicies from "views/Status/Policies/Rate/component";
import {PoliciesList as FlowPolicies} from "views/Status/Policies/Flow/component";
import {PoliciesList as MonitoringPolicies} from "views/Status/Policies/Monitoring/component";
import FlowsPerSubscriber from "views/Status/Flows/PerSubscriber/component";
import LoginContainer from "views/Login/component";
import Subscribers from "views/Status/SubscriberQuotas/component";
import CPUStatus from "views/Status/System/CPU/component";
import MemoryStatus from "views/Status/System/Memory/component";
import DiskStatus from "views/Status/System/Disk/component";
import BillingStatus from "views/Status/Billing/component";
import SubcriberDetail from "views/Statistics/Congestion/CongestionAndACM/component";
import {TCPOComparison as SpeedTCPOComparison} from "views/Statistics/TCPOptimization/Speed/component"
import {TCPOComparison as AccelerationTCPOComparison} from "views/Statistics/TCPOptimization/Acceleration/component"
import ProtocolsThroughput from "views/Statistics/Throughput/Protocols/component";
import PoliciesThroughputView from "views/Statistics/Throughput/PerPolicy/component";
import { ProfileContextProvider } from "common/ProfileContext";
import DoSVolumeStats from "views/Statistics/DoS/Volume/component";
import DoSSynStats from "views/Statistics/DoS/Syn/component";
import DPIHourlyVolume from "views/Statistics/DPI/HourlyVolume/component";
import DPITotalVolume from "views/Statistics/DPI/TotalVolume/component";
import DPILatency from "views/Statistics/DPI/Latency/component";
import SubscribersHourlyVolume from "views/Statistics/Subscribers/HourlyVolume/component";
import SubscribersTotalVolume from "views/Statistics/Subscribers/TotalVolume/component";
import PerPolicySubscribersView from "views/Statistics/Subscribers/PerPolicy/component";
import FlowsPerProtocol from "views/Statistics/Flows/PerProtocol/component";
import SysStatsCpu from "views/Statistics/System/SysStatsCpu/component";
import SysStatsMem from "views/Statistics/System/SysStatsMem/component";
import StatsLatency from "views/Statistics/System/StatsLatency/component";
import StatsRtx from "views/Statistics/System/StatsRtx/component";
import ConfigProfileTime from "views/Config/TimeProfiles/component";
import ConfigBilling from "views/Config/BillingView/component";
import RESTConfig from "views/Config/REST/component";
import RADIUSConfig from "views/Config/RADIUS/component";
import OptimizationConfig from "views/Config/Optimization/component";
import DoSConfig from "views/Config/DoS/component";
import ManagementInterfaceConfig from "views/Config/Interfaces/Management/component";
import ManagementFirewallConfig from "views/Config/Interfaces/Firewall/component";
import ManagementBypassConfig from "views/Config/Interfaces/Bypass/component";
import AdminSNMP from "views/Administration/SNMPView/component";
import KernelLogs from "views/Administration/Logs/Kernel/component";
import SystemLogs from "views/Administration/Logs/System/component";
import BackupLoadView from "views/Administration/Backup/Load/component";
import BackupSave from "views/Administration/Backup/Save/component";
import { toObjectLiteral } from "common/utils";
import ThroughputStatus from "views/Status/Interfaces/Throughput/component";
import DataWiresStatus from "views/Status/Interfaces/DataWires/component";
import LinkState from "views/Status/Interfaces/LinkState/component";
import DataWiresConfig from "views/Config/Interfaces/DataWires/component";

const router = createBrowserRouter([
  {
    path: "/viewIfaceStkStats",
    element: <RouteAdapter Component={Interfaces} />,
  },
  {
    path: "/viewBtaStatsTraffic",
    element: <RouteAdapter Component={BtaTraffic} />,
  },
  {
    path: "/viewStatusSubscribers",
    element: <RouteAdapter Component={SubscribersAndDetails} />,
  },
  {
    path: "/viewSubsDetail",
    element: <RouteAdapter Component={VerifiedSubcriberDetail} />,
  },
  {
    path: "/viewStatusSubscriberGroups",
    element: <RouteAdapter Component={SubscriberGroupsAndDashboard} />,
  },
  {
    path: "/viewSubscriberGroupDashboard",
    element: <RouteAdapter Component={SubscriberGroupDashboard} />,
  },
  {
    path: "/viewStatusSubscriberGroupsEdit",
    element: <RouteAdapter Component={SubscriberGroupEdit} />,
  },
  {
    path: "/viewStatusTCPO",
    element: <RouteAdapter Component={TCPOComparison} />,
  },
  {
    path: "/viewFlowsPerPolicy",
    element: <RouteAdapter Component={FlowsPerLPolicyHourly} />,
  },
  {
    path: "/viewRatePolicies",
    element: <RouteAdapter Component={RatePolicies} />,
  },
  {
    path: "/viewFlowPolicies",
    element: <RouteAdapter Component={FlowPolicies} />,
  },
  {
    path: "/viewMonitoringPolicies",
    element: <RouteAdapter Component={MonitoringPolicies} />,
  },
  {
    path: "/viewFlowsPerSubs",
    element: <RouteAdapter Component={FlowsPerSubscriber} />,
  },
  {
    path: "/viewLogin",
    element: <RouteAdapter Component={LoginContainer} />,
  },
  {
    path: "/viewSubscriberQuotas",
    element: <RouteAdapter Component={Subscribers} />,
  },
  {
    path: "/viewStatusSystemCPU",
    element: <RouteAdapter Component={CPUStatus} />,
  },
  {
    path: "/viewStatusSystemMemory",
    element: <RouteAdapter Component={MemoryStatus} />,
  },
  {
    path: "/viewStatusSystemDisk",
    element: <RouteAdapter Component={DiskStatus} />,
  },
  {
    path: "/viewStatusBilling",
    element: <RouteAdapter Component={BillingStatus} />,
  },
  {
    path: "/viewCongestionACM",
    element: <RouteAdapter Component={SubcriberDetail} />,
  },
  {
    path: "/viewTCPOptimizationSpeed",
    element: <RouteAdapter Component={SpeedTCPOComparison} />,
  },
  {
    path: "/viewTCPOptimizationAcceleration",
    element: <RouteAdapter Component={AccelerationTCPOComparison} />,
  },
  {
    path: "/viewThroughputProtocols",
    element: <RouteAdapter Component={ProtocolsThroughput} />,
  },
  {
    path: "/viewThroughputPerPolicy",
    element: <RouteAdapter Component={PoliciesThroughputView} />,
  },
  {
    path: "/viewDoSVolumeStats",
    element: <RouteAdapter Component={DoSVolumeStats} />,
  },
  {
    path: "/viewDoSSynStats",
    element: <RouteAdapter Component={DoSSynStats} />,
  },
  {
    path: "/viewDPIHourlyVolume",
    element: <RouteAdapter Component={DPIHourlyVolume} />,
  },
  {
    path: "/viewDPITotalVolume",
    element: <RouteAdapter Component={DPITotalVolume} />,
  },
  {
    path: "/viewDPILatency",
    element: <RouteAdapter Component={DPILatency} />,
  },
  {
    path: "/viewSubscribersHourlyVolume",
    element: <RouteAdapter Component={SubscribersHourlyVolume} />,
  },
  {
    path: "/viewSubscribersTotalVolume",
    element: <RouteAdapter Component={SubscribersTotalVolume} />,
  },
  {
    path: "/viewSubscribersPerPolicy",
    element: <RouteAdapter Component={PerPolicySubscribersView} />,
  },
  {
    path: "/viewBtaStatsSess",
    element: <RouteAdapter Component={FlowsPerProtocol} />,
  },
  {
    path: "/viewSysStatsCpu",
    element: <RouteAdapter Component={SysStatsCpu} />,
  },
  {
    path: "/viewSysStatsMem",
    element: <RouteAdapter Component={SysStatsMem} />,
  },
  {
    path: "/viewLatencyStats",
    element: <RouteAdapter Component={StatsLatency} />,
  },
  {
    path: "/viewRtxStats",
    element: <RouteAdapter Component={StatsRtx} />,
  },
  {
    path: "/viewProfTime",
    element: <RouteAdapter Component={ConfigProfileTime} />,
  },
  {
    path: "/viewBillingConf",
    element: <RouteAdapter Component={ConfigBilling} />,
  },
  {
    path: "/viewRESTConf",
    element: <RouteAdapter Component={RESTConfig} />,
  },
  {
    path: "/viewRADIUSConf",
    element: <RouteAdapter Component={RADIUSConfig} />,
  },
  {
    path: "/viewOptimizationConf",
    element: <RouteAdapter Component={OptimizationConfig} />,
  },
  {
    path: "/viewDoSConf",
    element: <RouteAdapter Component={DoSConfig} />,
  },
  {
    path: "/viewManagementInterfaceConf",
    element: <RouteAdapter Component={ManagementInterfaceConfig} />,
  },
  {
    path: "/viewManagementFirewallConf",
    element: <RouteAdapter Component={ManagementFirewallConfig} />,
  },
  {
    path: "/viewInterfacesBypassConf",
    element: <RouteAdapter Component={ManagementBypassConfig} />,
  },
  {
    path: "/viewSNMPConf",
    element: <RouteAdapter Component={AdminSNMP} />,
  },
  {
    path: "/viewLogsKernel",
    element: <RouteAdapter Component={KernelLogs} />,
  },
  {
    path: "/viewLogsSystem",
    element: <RouteAdapter Component={SystemLogs} />,
  },
  {
    path: "/viewBackupLoad",
    element: <RouteAdapter Component={BackupLoadView} />,
  },
  {
    path: "/viewBackupSave",
    element: <RouteAdapter Component={BackupSave} />,
  },
  {
    path: "/viewStatusInterfacesThroughput",
    element: <RouteAdapter Component={ThroughputStatus} />,
  },
  {
    path: "/viewStatusInterfacesDataWires",
    element: <RouteAdapter Component={DataWiresStatus} />,
  },
  {
    path: "/viewStatusInterfacesLinkState",
    element: <RouteAdapter Component={LinkState} />,
  },
  {
    path: "/viewInterfacesDataWiresConf",
    element: <RouteAdapter Component={DataWiresConfig} />,
  },
  {
    path: "/:view?",
    element: <RouteAdapter />,
  },
]);

export default function App() {
  return (
    <ProfileContextProvider>
      <RouterProvider router={router} />
    </ProfileContextProvider>
  )
}

function RouteAdapter({ Component }) {
  const [ready, setReady] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const [searchParams, _] = useSearchParams();

  function filterParams(input) {
    const output = {};
    for (const [key, value] of Object.entries(input)) {
      if (value !== undefined && value !== null && key !== "dpiEnabled") {
        output[key] = value;
      }
    }

    return output;
  }

  function navigateWithQueryParams(path, queryParams) {
    if(location.pathname === '/viewDashboard'){
      window.unloadDashboardView();
    }
    
    navigate({
      pathname: "/" + path,
      search: createSearchParams(
        queryParams ? filterParams(queryParams) : undefined
      ).toString(),
    });
  }

  function navigateBack() {
    navigate(-1);
  }

  function navigateBackJs(element) {
    $(element).hide(250);
    navigate(-1);
  }

  function updateUI() {
    if(location.pathname === "/viewLogin"){
      $("#navigationBar").hide();
      $("#sectSidebars").hide();
    }else{
      $("#navigationBar").show();
      $("#sectSidebars").show();
    }
    $("#sectContent").hide();
    window.views.setMenuActive("#menu" + location.pathname.substring(5));
  }

  function checkJsReady(){
    if(window.subsStatsMetrics && window.login){
      setReady(true);
    }else{
      setTimeout(() => {
        checkJsReady();
      }, 1000)
    }
  }

  useEffect(() => {
    window.globalNavigate = navigateWithQueryParams;
    window.globalNavigateBack = navigateBack;
    window.globalNavigateBackJs = navigateBackJs;

    checkJsReady();
  }, []);

  useEffect(() => {
    if(!ready){
      return;
    }

    const isLogged = window.login.isLogged();
    if (location.pathname !== "/viewLogin" && !isLogged) {
      return navigate({
        pathname: "/viewLogin",
        search: createSearchParams({
          url: location.pathname.slice(1),
        }).toString(),
      });
    } else if (location.pathname === "/" && location.hash === '' && isLogged) {
      return navigate("/viewDashboard");
    }

    if (!Component && params.view && (params.view === "viewLogin" || isLogged)) {
      const dpiFunctionality = window.functionality.isEnabled("dpi-per-user");
      window.views.toggle(params.view, {
        ...toObjectLiteral(searchParams),
        dpiEnabled: dpiFunctionality,
      });
    }else if(location.hash !== ''){
      location.href = location.href.replace('#', '');
    }
  }, [params.view, ready]);


  if (!ready || !Component) {
    return null;
  }

  updateUI();

  const dpiEnabled = window.functionality.isEnabled("dpi-per-user");

  return (
    <ActionableView
      Component={Component}
      {...toObjectLiteral(searchParams)}
      dpiEnabled={dpiEnabled}
    />
  );
}
