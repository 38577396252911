import React, { useRef, useEffect } from "react";
import Canvas from "./Canvas";
import * as d3 from "d3";
import styled from "styled-components";
import { remaining } from "./Axis";
///https://d3-graph-gallery.com/graph/barplot_grouped_basicWide.html

const colorRule = ({ field, color }) => `
  & .field-label.${field},
  & .bar.${field} {
    fill: ${color};
  }
  & text.field-label.${field}, & rect.bar.${field} , & tspan.${field} {
    fill: ${color};
  }
`;

const StyledG = styled.g`
  & .field-label {
    font-size: 1em;
    fill: currentcolor;
    text-anchor: middle;
  }
  & .field-label,
  & .field-label tspan {
    alignment-baseline: auto;
  }
`;

const _DEFAULT_MARGIN = { top: 10, right: 50, bottom: 40, left: 50 };

const adaptedXScale = (
  axis,
  { paddingInner = 0.2, paddingOuter = 0.2, align = 0.5, round = true }
) => {
  const scale = axis.scale
    .copy()
    .paddingInner(paddingInner)
    .paddingOuter(paddingOuter)
    .align(0.5)
    .round(round);
  const scaledValue = (d) => scale(axis.value(d));
  return {
    ...axis,
    scaledValue,
    scale,
  };
};

const given = ({ height, width }, { xAxis, yAxis }) => ({
  calcReverseHeight: (d) => {
    const dValue = yAxis.value(d);
    const rectHeight = height - yAxis.scale(dValue === null ? 0 : dValue);
    if (isNaN(rectHeight) || rectHeight < 0) {
      console.warn(
        `Invalid height value: ${rectHeight} from value ${dValue}
  width: ${width} heigtht ${height}
  axis domain: ${yAxis.scale.domain()}, axis range ${yAxis.scale.range()}
  defaults to 0`
      );
      return 0;
    }
    return rectHeight;
  },
});

const BarChart = ({
  canvas,
  data = [],
  axis = {},
  xFrom = "x",
  yFrom = "y",
  dimensions = null,
  margin = _DEFAULT_MARGIN,
  precision = 2,
  label,
  items = [],
  ...scaleParams
}) => {
  const chart = useRef(null);
  label =
    label !== undefined
      ? label
      : (d) => (d.value === null ? "--" : d.value.toFixed(precision));
  useEffect(() => {
    if (dimensions === null) {
      return;
    }
    const { width, height } = remaining(dimensions, margin);
    if (width < 1 || height < 1) {
      return;
    }
    const xAxis = adaptedXScale(axis[xFrom], scaleParams);
    const yAxis = axis[yFrom];
    const xScale = xAxis.scale;
    const yScale = yAxis.scale;

    // Position main container.
    const main = d3.select(chart.current);
    main.attr("transform", "translate(" + margin.left + "," + margin.top + ")");

    // Put groups into main container
    // And bars inside those groups
    const bars = main.selectAll(".bar").data(items);
    const newBars = bars
      .enter()
      .append("rect")
      .attr("class", (d) => `bar ${d.key}`);
    bars
      .merge(newBars)
      .attr("x", (d, i) => xAxis.scaledValue(d))
      .attr("width", xAxis.scale.bandwidth())
      .attr("y", (d) => yAxis.scaledValue(d))
      .attr("height", given(dimensions, { xAxis, yAxis }).calcReverseHeight);

    // Finally add that bars some value
    const fieldLabels = main.selectAll(".field-label").data(items);
    const newFieldLabels = fieldLabels
      .enter()
      .append("text")
      .attr("class", (d) => `field-label ${d.key}`);
    fieldLabels.exit().remove();
    fieldLabels
      .merge(newFieldLabels)
      .text(label)
      .attr("x", (d) => xAxis.scaledValue(d) + xAxis.scale.bandwidth() / 2)
      .attr("y", (d) => (d.value === null ? height : yAxis.scaledValue(d)))
      .attr("dy", "-0.25em");

    return () => {};
  }, [chart, canvas, dimensions, axis]);

  return <StyledG ref={chart}></StyledG>;
};

export default BarChart;
