import React, { useRef, useEffect, useState } from "react";
import Canvas from "./Canvas";
import * as d3 from "d3";
import styled from "styled-components";
import Axis, { remaining } from "./Axis";
import BarChart from "./BarChart";
///https://d3-graph-gallery.com/graph/barplot_grouped_basicWide.html

const colorRule = ({ field, color }) => `
  & .field-label.${field},
  & .bar.${field} {
    color: ${color};
    fill: currentColor;
  }
  & text.field-label.${field}, & rect.bar.${field} , & tspan.${field} , & span.${field}{
    color: ${color};
    fill: currentColor;
  }
`;

const GroupsDiv = styled.div`
  flex: 1 1 100%;
  display: grid;
  grid-auto-columns: 1fr;
  grid-template-rows: [graph] 1fr [label];
  justify-content: center;

  & .group-label {
    grid-row: label;
    font-size: 1.1em;
    fill: currentcolor;
    text-anchor: middle;
  }
  & .group-graph {
    grid-row: graph;
  }
  & .group-label,
  & .group-graph {
    max-width: 25vw;
    justify-self: center;
  }
  ${({ columns = [] }) => columns.map(colorRule).join("/n")}
  @media only screen and (max-width: 768px) {
    display: flex;
    flex-direction: column;
    & .group {
      flex: 1 1 100%;
    }
    & .group-label,
    & .group-graph {
      grid-row: graph;
      max-width: none;
    }
  }
`;

const _DEFAULT_MARGIN = { top: 10, right: 50, bottom: 50, left: 50 };

const _DEFAULT_INNER_MARGIN = { top: 20, right: 0, bottom: 20, left: 0 };

const GroupLabel = styled.div`
  text-align: center;
`;

const GroupBarChart = ({
  label,
  fieldLabel,
  allValues,
  items = [],
  margin = _DEFAULT_INNER_MARGIN,
}) => {
  return (
    <>
      <Canvas className="group-graph">
        <Axis
          margin={margin}
          data={items}
          are={[
            {
              name: "x",
              type: "band",
              bandWidth: "30px",
              value: (d) => d.key,
              along: "width",
            },
            {
              name: "y",
              values: allValues,
              value: (d) => d.value,
              along: "height",
            },
          ]}
        >
          <BarChart items={items} label={fieldLabel} margin={margin} />
        </Axis>
      </Canvas>
      <GroupLabel className="group-label">{label}</GroupLabel>
    </>
  );
};

const addItems =
  (fields = []) =>
  (group) => ({
    ...group,
    items: fields.map((field) => ({
      key: field,
      value: group[field],
    })),
  });

const groupExtent = ({ items }) => [
  d3.max(items.map((item) => item.value).flat()),
  0,
];

const GroupsBarChart = ({
  data = [],
  groupLabel = null,
  fieldLabel = null,
  columns = [],
  margin = _DEFAULT_MARGIN,
  independentScales = false,
}) => {
  const [groups, setGroups] = useState([]);
  const [values, setValues] = useState([]);
  useEffect(() => {
    const fields = columns.map((column) => column.field);
    setGroups(data.map(addItems(fields)));
  }, [data, columns]);
  useEffect(() => {
    if (groups.length === 0) {
      return;
    }
    const maxYValue = d3.max(
      groups.map((group) => group.items.map((item) => item.value).flat()).flat()
    );
    setValues([maxYValue, 0]);
  }, [groups]);
  return (
    <GroupsDiv columns={columns}>
      {groups.map((group) => (
        <GroupBarChart
          key={group.key}
          id={group.key}
          items={group.items}
          allValues={independentScales ? groupExtent(group) : values}
          label={groupLabel(group)}
          fieldLabel={fieldLabel}
          margin={margin}
        />
      ))}
    </GroupsDiv>
  );
};

export default GroupsBarChart;
