import React, { useState, useEffect, useContext } from "react";
import Tabs, { Tab } from "common/layouts/Tabs";
import TCPOptimizationSpeedHelpModal from "./Help";
import {
  HeaderDiv,
  ViewContainer,
  ContextMenu,
  LabelRetrieval,
  FilteringSettings,
  DaysContext,
} from "../common";
import ActionsContext from "common/ActionsContext";

const speedFacets = ({
  RttiLo = "0-10ms",
  RttiMd = "10-60ms",
  RttiHi = ">60ms",
}) => [
  {
    label: "All Internet Latencies",
    fields: [
      { name: "optimized-global", label: "TCPO", color: "var(--bta-color)" },
      { name: "global", label: "No TCPO", color: "var(--no-bta-color)" },
    ],
    noDataMessage: "Not enough samples",
  },
  {
    label: `${RttiLo} Internet Latency`,
    fields: [
      { name: "optimized-small", label: "TCPO", color: "var(--bta-color)" },
      { name: "small", label: "No TCPO", color: "var(--no-bta-color)" },
    ],
    noDataMessage: "Not enough samples",
  },
  {
    label: `${RttiMd} Internet Latency`,
    fields: [
      { name: "optimized-medium", label: "TCPO", color: "var(--bta-color)" },
      { name: "medium", label: "No TCPO", color: "var(--no-bta-color)" },
    ],
    noDataMessage: "Not enough samples",
  },
  {
    label: `${RttiHi} Internet Latency`,
    fields: [
      { name: "optimized-large", label: "TCPO", color: "var(--bta-color)" },
      { name: "large", label: "No TCPO", color: "var(--no-bta-color)" },
    ],
    noDataMessage: "Not enough samples",
  },
];

export const TCPOComparison = ({ hours = 24 }) => {
  const [params, setParams] = useState({ hours });
  const onHoursChange = (hours) => setParams({ hours });
  return (
    <ViewContainer className="col-lg-12 col-md-12 col-sm-12 col-xs-12">
      <div className="card">
        <HeaderDiv className="header block-header">
          <h2 className="title">Average TCP connections speed over time</h2>
          <ContextMenu />
        </HeaderDiv>
        <div className="body flex-panel-column">
          <LabelRetrieval>
            {(labels) => (
              <Tabs>
                <Tab label="DOWNLOAD" id="down" active={true}>
                  <FilteringSettings
                    direction="down"
                    {...params}
                    onHoursChange={onHoursChange}
                  >
                    {(result) => (
                      <DaysContext facets={speedFacets(labels)} {...result} />
                    )}
                  </FilteringSettings>
                </Tab>
                <Tab label="UPLOAD" id="up">
                  <FilteringSettings
                    direction="up"
                    {...params}
                    onHoursChange={onHoursChange}
                  >
                    {(result) => (
                      <DaysContext facets={speedFacets(labels)} {...result} />
                    )}
                  </FilteringSettings>
                </Tab>
              </Tabs>
            )}
          </LabelRetrieval>
        </div>
      </div>
      <TCPOptimizationSpeedHelpModal />
    </ViewContainer>
  );
};